$blockHeight: 46.2rem;

.newsGrid {

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax($blockHeight, auto);
    grid-gap: 1px;
    grid-auto-flow: dense;
    padding: 1px;

    @include lg-up {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    position: relative;
    overflow: hidden;
    display: flex;
    transition: all 0.5s ease;

    &_small {
      height: $blockHeight;

      @include lg-up {
        grid-column-end: span 2;
      }

      @include xl-up {
        grid-column-end: span 1;
      }
    }

    &_medium {
      height: $blockHeight;

      @include lg-up {
        grid-column-end: span 2;
      }
    }

    &_large {
      height: $blockHeight;

      @include lg-up {
        height: $blockHeight * 2;
        grid-column-end: span 2;
        grid-row-end: span 2;
      }
    }

    &_context {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 2.4rem;

      @include lg-up {
        display: none;
      }

      @include xl-up {
        padding: 8rem;
      }

      &.mobile__view {
        display: block;
      }
    }

    .image {
      mix-blend-mode: multiply;
      width: 100%;

      @include md-up {
        mix-blend-mode: normal;
      }

      &.container {
        max-width: none;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: grayscale(1);
    };

    &:hover .bg-red {
      display: block;
      mix-blend-mode: multiply;
    }

    &:hover .image {
      mix-blend-mode: multiply;
    }

    &:hover .newsGrid__item_context {
      display: block;
    }

    &.noBackground {
      background-color: $black;

      .newsGrid__item_context {
        display: block;
      }
    }

    a::after {
      background-color: $white;
    }
  }
}