.contact {

  &__map_wrapper {
    // margin-top: 12rem;
    margin-bottom: 4rem;
    flex-direction: column;

    @include md-up {
      flex-direction: row;
    }
  }

  &__map {
    // width: 784px;
    height: 320px;   
    
    @include md-up {
      height: 640px; 
    }
  }

  svg {
    @include squaresize(2.4rem);
  }

  &__data {
    // margin-left: 2.4rem;
    @include md-up {
      margin-left: 2.4rem;
    }

    @include lg-up {
      margin-left: 8rem;
    }
  }

  &__form_title {
    margin-top: 6.4rem;

    @include xl-up {
      margin-top: 8rem;
    }
  }
}

.submit_button {
  position: relative;
  background-color: transparent;
  outline: none;
  @include font(2.0rem, 600, 135%);
  align-self: end;

  @include xxl-up {
    font-size: 2.6rem;
  }

  // &:hover {
  //   color: $primary;
  // }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0px;
    height: 2px;
    background-color: $primary;
    transition: all .5s;
  }

  &:hover {
    color: $black;
  }

  &:hover::after {
    width: 100%;
  }
}
