.teaser {
  margin-top: 4rem !important;
  margin-bottom: 6.4rem;

  @include md-up {
    margin-bottom: 8rem;;
  }

  &__inner:hover img {
    filter: none;
  }

  .image {
    max-width: none;
  }

  &__img {
    object-fit: cover;
    object-position: top center;
    aspect-ratio: 4 / 3;
    filter: grayscale(1);
    transition: all .2s;
  }

  &__button {
    @include font(1.8rem, 600, 135%, $black);
    display: inline-block;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;

    @include md-up {
      @include font(2.0rem, 600, 135%, $black);
    }

    @include xxl-up {
      @include font(2.6rem, 600, 135%, $black);
    }

    &::after {
      content: '';
      display: block;
      position: relative;
      width: 0px;
      height: 2px;
      background-color: $primary;
      transition: all .5s;
    }

    &:hover {
      color: $black;
    }

    &:hover::after {
      width: 100%;
    }
  }

  &__inner {
    transition: all 0.3s ease, visibility 0s;
    opacity: 1;

    &__anim {
      transform: translateY(10rem);
      opacity: 0;
    }  
  }

  &__topic {
    color: $primary;
  }

  .cuttedText,
  .cuttedText p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}