.project,
.kurzportrait {
  
  .swiper__project {
    position: relative;
    height: calc(100vh - 9.4rem);
    overflow: hidden;

    .swiper-slide-next .project__content {
      transition: all 0.8s ease;
      transform: translateY(10rem) !important;
      opacity: 0 !important;
    }
    
    .project__content_next {
      transition: all 0.8s ease !important;
    }
  }
  .swiper-button-wrapper {
    justify-content: center;
  }

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-start {

    position: relative;
    width: fit-content;
    color: $black;
    margin-top: 0;
    left: auto;
    z-index: 9;

    &::after,
    &::before {
      display: none;
    }
    svg {
      display: block;
      margin: 0 auto;
      @include squaresize(3.2rem);
      fill: $primary;
    }
    p {
      opacity: 0.5;
    }
  }

  .swiper-button-start {
    display: none;
    position: relative;
    right: 9rem;

    &.visible {
      display: block;
      transform: rotate(90deg);
      cursor: pointer;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-slide {
    position: relative;
  }

  .project__mobile {
    .image__wrapper,
    .project__content_extra {
      transition: all 0.8s ease;
    }
  }

  .image__wrapper {
    justify-content: flex-start;

    &.height-fit {
      height: fit-content;
    }

    @include md-up {
      overflow-y: scroll;
      flex-wrap: wrap;
    }

    .image.container {
      max-width: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &_textBlock,
    &_textBlock p {
      font-size: 2.8rem;
      font-weight: 700;

      @include md-up {
        font-size: 3.2rem;
      }
    }
  }

  &__content:not(.project__content_extra) {
    height: calc(100vh - 10rem);
  }
  .project__content_mobile {
    height: fit-content;
  }

  &__content,
  &__content_extra {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 2.4rem 2.4rem 2.4rem;
    overflow-y: scroll;
    will-change: transform;

    @include lg-up {
      padding: 0 4.8rem 2.4rem 4.8rem;
    }

    @include xl-up {
      padding: 0 6.4rem 2.4rem 6.4rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-text {
      font-style: italic;
      font-weight: 700;
      line-height: 120%;
    }

    &_params p {
      margin-bottom: 0.4rem;
    }

    &_description {
      flex-wrap: wrap;
    }
  }
  &__content_next {
    height: 100%;
    justify-content: space-between;
  }

  &__content_extra {
    padding-bottom: 0;

    &_wrapper {
      padding-bottom: 0;
      justify-content: flex-start;
    }
  }

  &__arrow {
    transform: rotate(90deg);
    position: absolute;
    bottom: 2.4rem;
    left: calc(25% - 0.7rem);

    animation-name: arrowMoving;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    display: none;

    svg {
      animation-name: arrowMoving;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }

    &.right-position {
      position: fixed;
      left: auto;
      right: calc(15% - 0.7rem);
    }

    &.visible {
      display: block;
    }

    &.rotate {
      transform: rotate(270deg);
    }
  }

  .image__target {
    margin-bottom: 0.4rem; 

    @include md-up {
      margin-bottom: 1.6rem; 
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.horizontal {
  width: 100%;
  margin-bottom: 2.2%;
  aspect-ratio: 16 / 9;
  
  .image {
    width: 100%;
  }

  img {
    aspect-ratio: 16 / 9;
  }
}

.verticalLeft,
.verticalRight {
  width: 100%;
  margin-bottom: 2.2%;
  aspect-ratio: 3 / 4;

  @include md-up {
    width: 74.1%;
  }

  img {
    aspect-ratio: 3 / 4;
  }
}

.verticalRight {

  @include md-up {
    margin-left: auto;

    .image {
      justify-content: flex-end;
    }
  }
}

.twoColumnLeft{
  width: 100%;
  margin-bottom: 2.2%;
  aspect-ratio: 16 / 9;

  img {
    aspect-ratio: 16 / 9;
  }

  @include md-up {
    margin-right: 2.2%;
    width: 68.9%;
    aspect-ratio: 1 / 1;

    img {
      aspect-ratio: 1 / 1;
    }
  }
}

.twoColumnRight{
  width: 100%;
  margin-bottom: 2.2%;
  aspect-ratio: 3 / 4;

  @include md-up {
    width: 28.8%;
  }

  img {
    aspect-ratio: 3 / 4;
  }
}

@keyframes arrowMoving {
  from {
    bottom: 2.4rem;
    fill: $primary;
  }

  50% {
    bottom: 3.4rem;
    fill: $primary-2;
  }

  to {
    bottom: 2.4rem;
    fill: $primary;
  }
}

.project {
  .swiper-button-prev {

    svg {
      transform: rotate(-90deg);
    }
    
  }

  .swiper-button-next {
    svg {
      transform: rotate(90deg);
    }
  }
}
