.aside {

  &__inner {
    overflow: hidden;
  }


  &.imageBlock {
    
    .aside__image {
      width: 100%;

      @include md-up {
        width: 70%;
        // margin-right: 8rem;
      }

      @include lg-up {
        width: 33.3%;
        margin-right: 8rem;
      }

      @include xxl-up {
        margin-right: 10rem;
      }

      .image {
        max-width: none;
      }
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        aspect-ratio: 3 / 4;
      }
    }

    .aside__content {
      padding: 4rem 2.4rem;

      @include md-up {
        padding: 0rem;
      }

      a {
        display: inline-block;
        &::after {
          content: '';
          display: block;
          position: relative;
          width: 0px;
          height: 2px;
          background-color: $primary;
          transition: all .5s;
        }

        &:hover {
          color: $black;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }

    .m-left img {

      @include lg-up {
        margin-left: 8rem;
      }

      @include xxl-up {
        margin-left: 10rem;
      }
    }

    .m-right img {

      @include lg-up {
        margin-right: 8rem;
      }

      @include xxl-up {
        margin-right: 0rem;
      }
    }

    .landscape img{
      aspect-ratio: 4 / 3;
    }
  }

  &.quoteColumn {

    .color-D0D7DD {
      background-color: $secondary;
    }

    .color-E9DBD1 {
      background-color: $secondary-2;
    }

    .color-CFCFC4 {
      background-color: #CFCFC4;
    }

    .quote {

      &__wrapper {
        padding: 12rem 2.4rem 20rem;

        @include lg-up {
          padding: 20rem 14.4rem 20rem 9.6rem;
        }

        @include xxl-up {
          padding: 24rem 19rem 20rem 13rem;
        }
      }

      &__content_wrapper {
        padding: 4rem 2.4rem;

        @include lg-up {
          padding: 44rem 9.6rem 18.8rem 14.4rem;
        }

        @include xxl-up {
          padding: 48rem 13rem 32rem 19rem;
        }
      }
    }

    .quoteColumn__image {
      order: 2;

      .image {
        max-width: none;
        width: 100%;
        height: 100%;
        overflow: hidden;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          aspect-ratio: 1 / 1;
        }
      }

      @include md-up {
        order: 0;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        object-fit: contain;
        object-position: center;
        height: 100%;
      }
    }

    .image__content_wrapper {
      padding: 4rem 2.4rem;
      order: 0;

      @include lg-up {
        padding: 13.5rem 14.4rem 20rem 9.6rem;
        order: 1;
      }

      @include xxl-up {
        padding: 16rem 19rem 20rem 13rem;
      }
    }
  }
}

.content-section.quote__section {
  padding-bottom: 4rem;

  @include md-up {
    padding-bottom: 0rem;

    &:last-of-type {
      padding-bottom: 6.4rem;
    }
  }
}


.imageBlock__section {

  .imageBlock {
    margin-top: 4rem;
    margin-bottom: 4rem;

    @include md-up {
      margin-top: 8rem;
      margin-bottom: 8rem;
      padding: 0rem 6.4rem 0rem;
    }

    @include xxl-up {
      margin-top: 12rem;
      margin-bottom: 12rem;
      padding: 0rem 8rem 0rem;
    }

    // .image {
    //   height: 100%;
    // }
  }
}

.content-section {
  padding: 0rem 2.4rem 0rem;
  margin-top: 4rem;

  @include md-up {
    padding: 0rem 6.4rem 0rem;
    margin-top: 8rem;
  }

  @include xxl-up {
    padding: 0rem 8rem 0rem;
    margin-top: 12rem;
  }
}

.notFound {
  
  .image {
    overflow: hidden;

    @include md-up {
      height: calc(100vh - 7.8rem);
    }

    @include lg-up {
      height: calc(100vh - 9.4rem);
    }

    &.container {
      max-width: none;
    }

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      filter: grayscale(1);
      aspect-ratio: 4 / 3;

      @include md-up {
        aspect-ratio: auto;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.4rem 2.4rem 4rem 2.4rem;

    @include lg-up {
      padding: 0rem 8rem 0rem 6.4rem;
    }
  
    @include xxl-up {
      padding: 0rem 14.4rem 0rem 8rem;
    }

    a {
      padding: 0;

      &:hover {
        color: $primary;
      }
    }
  }
}
