.kurzportrait {

  &__wrapper {
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease !important;

    @include md-up {
      flex-direction: row-reverse;
    }

    .image {
      margin-bottom: 0.4rem;
  
      &.container {
        max-width: none;
      }

      &__wrapper {
        height: fit-content;

        @include md-up {
          display: flex;
        }

        .aside__img {
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  &__content {
    padding-bottom: 4rem;
    height: fit-content !important;
    padding: 0 2.4rem 2.4rem 2.4rem;


    @include lg-up {
      padding: 0 4.8rem 2.4rem 4.8rem;
    }

    @include xl-up {
      padding: 0 6.4rem 2.4rem 6.4rem;
    }

    &_wrapper {
      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__image {
    height: fit-content;
  }

  .image__wrapper {

    @include md-up {
      overflow-y: scroll;
      flex-wrap: wrap;
    }

    .image.container {
      max-width: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .content-arrow {
    left: auto;
    right: calc(25% - 0.7rem);
  }

  .image__kurzportrait {

    &_textBlock,
    &_textBlock h3 {
      font-size: 2.8rem;
      font-weight: 700;
  
      @include md-up {
        font-size: 3.2rem;
      }
    }
  
    &_textBlock,
    &_textBlock p {
      font-size: 2.2rem;
      font-weight: 400;
  
      @include md-up {
        font-size: 2.6rem;
      }
    }
  }
}
