.team {
  transition: all .5s;
  max-width: 200rem;
  margin-left: auto;
  margin-right: auto;

  @include lg-up {
    height: calc(100vh - 9.4rem);
    overflow: hidden;
  }

  &__inner {
    min-height: calc(100vh - 25.4rem);
    display: none;

    @include lg-up {
      height: calc(100vh - 9.4rem);
      overflow-y: scroll;

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.active {
      display: flex;
    }
  }

  &__img_start {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center;
    width: 100%;

    @include lg-up {
      // 16.2 rem - height of quote block
      // 9.4rem is header height
      // 2rem - padding bottom to quote
      height: calc(100vh - 16.2rem - 9.4rem - 2rem);
      max-height: 64rem;
      object-position: center 80%;
    }
  }

  &__image .image {
    overflow: hidden;
    max-width: none;
    justify-content: center;

    img {
      aspect-ratio: 9 / 14;
      object-fit: cover;
      object-position: center;
      // width: 100%;
      // 9.4rem is header height
      height: calc(100vh - 9.4rem); 
      max-height: 90rem; 
    }
  }

  &__quote {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2.4rem;
    
    .quote-text {
      font-size: 2.8rem;
      font-style: italic;
      font-weight: $font-weight-bold;
      line-height: 110%;
    }

    @include lg-up {
      margin-top: 4rem;

      .quote-text {
        font-size: 3.2rem;
      }
    }
  }

  &__content {
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include lg-up {
      overflow-y: scroll;

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    h1 {

      @include lg-up {
        margin-top: 14rem;
      }

      @include xxl-up {
        margin-top: 18rem;
      }
    }
  }

  &__contact {

    @include font(1.8rem, 400, 135%);

    @include lg-up {
      @include font(2.0rem, 400, 135%);
    }

    @include xxl-up {
      @include font(2.6rem, 400, 135%);
    }

    &:hover span,
    &:hover svg {
      color: $primary;
    }

    svg {
      @include squaresize(2.4rem);
    }
  }

  &__list {
    padding-top: 4rem;
    padding-bottom: 4rem;
    // overflow: hidden;
    display: none;

    @include lg-up {
      display: block;
      padding-bottom: 8rem;
      overflow-y: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &_mobile {
      display: block;

      @include lg-up {
        display: none;
      }
    }

    &_item {
      margin-bottom: .8rem;

      &:hover,
      &.active {
        color: $primary;
        cursor: pointer;
      }

    }
  }

  .project__arrow.arrow-cv {
    display: none;

    @include lg-up {

      &.visible {
        display: block;
      }
    }
  }
}
