.three-teaser {
    max-width: 131.2rem;
    margin: 0 auto;
    padding: 0 5.6rem;

    @include md-up {
      padding: 0 11.2rem;
    }
    
  .slider-container {
    
    .swiper-pagination {
      top: 0;
      transform: translateY(-100%);
      height: max-content;
    }

    .swiper {
      padding: 0 0.2rem;
    }

    .swiper-button-prev {
      left: -4.2rem;

      @include md-up {
        left: -11.2rem;
      }
    }
    .swiper-button-next {
      right: -4.2rem;

      @include md-up {
        right: -11.2rem;
      }
    }

    &__item {
      width: 34rem;
      .teaser__link {
        .icon {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
  }

  &__cta {
    max-width: fit-content;
    margin: 0 auto;
  }
}