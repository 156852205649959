.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

.m-top-4 {
  margin-top: 4rem;
}

.m-bottom-4 {
  margin-bottom: 4rem;
}

.m-right-4 {
  margin-right: 4rem;
}

.m-left-4 {
  margin-left: 4rem;
}

.m-top-8 {
  margin-top: 8rem;
}

.m-bottom-8 {
  margin-bottom: 8rem;
}

.m-right-8 {
  margin-right: 8rem;
}

.m-left-8 {
  margin-left: 8rem;
}

.p-all-8 {
  padding: 8rem;
}

.h-50vh {
  height: 50vh;
}

.flex-start-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

// Background colors
.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-black {
  background-color: $black !important;
}

.bg-gray-100 {
  background-color: $gray-100 !important;
}

.bg-gray-200 {
  background-color: $gray-200 !important;
}

.bg-gray-300 {
  background-color: $gray-300 !important;
}

.bg-gray-400 {
  background-color: $gray-400 !important;
}

// Text colors
.color-primary {
  color: $primary;
}
.color-secondary {
  color: $secondary;
}

.link-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

// cursort pointer
.c-p {
  cursor: pointer;
}

.button {
  @include font(2.0rem, 400, 135%, $white);
  cursor: pointer;
}

.scrollAnim {
  transition: all 0.5s ease !important;
  transform: translateY(10rem) !important;
  opacity: 0 !important;
}

.blockCursor {
  // pointer-events: none !important;
  cursor: wait !important;
}