.gallery {

  &__description {
    margin-bottom: 4rem;

    @include md-up {
      margin-bottom: 8rem;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    order: 1;

    @include md-up {
      grid-template-columns: repeat(3, 1fr);
      gap: 3.2rem;
      order: 0;
      padding: 0 8rem;
    }
  }

  &__column_1 {
    margin-top: 0rem;

    @include md-up {
      margin-top: 6.4rem;
    }
  }

  &__column_3 {
    margin-top: 0rem;

    @include md-up {
      margin-top: 11.2rem;
    }
  }

  &__image {
    margin-bottom: 3.2rem;
    cursor: pointer;

    .image.container {
      max-width: none;
    }

    img {
      object-fit: cover;
      object-position: center;
      @include squaresize(100%);
      aspect-ratio: 3 / 4;
    }

     &.landscape {
      img {
        aspect-ratio: 4 / 3;
      }
    }
  }
}