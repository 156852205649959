.pageNavigation {
  margin-top: 3.2rem;
  margin-bottom: 4rem;
  
  @include md-up {
    margin-top: 8rem;
    margin-bottom: 12rem;
  }

  &__block {
    // justify-content: center;

    // @include md-up {
    //   justify-content: flex-start;
    // }

    &_end {
      @include md-up {
        justify-content: flex-end;
      }
    }

    svg {
      color: $black;
      max-height: 1.6rem;
    }

    &_title {
      @include font(1.6rem, 400, 135%, $black);
    }
  }

  &__btn {
    justify-content: center;
    align-items: center;
    color: $black;

    // &:first-of-type {
    //   @include md-up {
    //     margin-left: -3.2rem;
    //   }
    // }

    &:hover,
    &:hover svg{
      color: $primary;
    }

    & ~ span {
      font-weight: 900;
      color: $primary;
      margin-bottom: 4px;
    }
  }

  .border-right {
    border-right: 3px solid $primary;
  }
}