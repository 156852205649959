.pageSlider {

  &__wrapper {
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease !important;

    @include md-up {
      flex-direction: row-reverse;
    }

    .image {
      margin-bottom: 0.4rem;
  
      &.container {
        max-width: none;
      }

      &__wrapper {
        height: fit-content;

        @include md-up {
          display: flex;
        }

        .aside__img {
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  &__content {
    padding-bottom: 4rem;
    height: fit-content;
    padding: 0 2.4rem 2.4rem 2.4rem;


    @include lg-up {
      padding: 0 4.8rem 2.4rem 4.8rem;
      // height: calc(100vh - 10rem);
    }

    @include xl-up {
      padding: 0 6.4rem 2.4rem 6.4rem;
    }
      
    // &_wrapper {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    // }

    blockquote {
      margin: 4rem 0 0;

      @include md-up {
        &.huge,
        &.huge p {
          font-size: 3.2rem;
        }
      }
    }
  }







  // .project__mobile_wrapper {
  //   transition: all 0.5s ease !important;
  // }

  // .swiper-slide-next .project__content {
  //   transition: all 0.8s ease;
  //   transform: translateY(10rem) !important;
  //   opacity: 0 !important;
  // }

  // .project__content_next {
  //   transition: all 0.8s ease !important;
  // }

  // .swiper__pageSlider {
  //   position: relative;
  //   height: calc(100vh - 9.4rem);
  //   overflow: hidden;
  // }

  // .swiper-button-prev,
  // .swiper-button-next {
  //   right: 4rem;
  //   left: auto;
  //   transform: rotate(90deg);
  //   &::after,
  //   &::before {
  //     display: none;
  //   }
  //   svg {
  //     @include squaresize(3.2rem);
  //     fill: $primary;
  //   }
    
  // }

  // .swiper-button-prev {
  //   top: 50%;
  //   transform: rotate(-90deg);
  // }

  // .swiper-button-next {
  //   top: auto;
  //   bottom: 40%;
  // }

  // .swiper-slide {
  //   position: relative;
  // }

  &__image {
    height: fit-content;
  }

  .image__wrapper {

    @include md-up {
      overflow-y: scroll;
      flex-wrap: wrap;
    }

    .image.container {
      max-width: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  

  
}