.btn {
  position: relative;
  padding: 1.4rem 2.4rem;
  // transition: all 0.5s ease;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  min-width: fit-content;
  max-width: fit-content;

  &--default {
    background-color: transparent;
    border: 0.1rem solid $cta;
    color: $cta;

    &:hover {
      color: $cta-hover;
      border-color: $cta-hover;
    }
    &:active {
      border-color: $cta-active;
      color: $cta-active;
    }
  }

  &--primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: rgba($primary, 0.5);
    }

    &:active {
      background-color: $active-color;
    }
  }

  &--ghost {
    background-color: transparent;
    border: 0.1rem solid #CCD7E9;
    color: #CCD7E9;

    &:hover {
      border-color: $white;
      color: $white;
    }
  }

  &--light {
    background-color: transparent;
    border: 0.1rem solid $white;
    color: $white;

    &:hover {
      border-color: $white;
      color: $white;
    }
  }

  &--secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: rgba($secondary, 0.5);
    }
   
  }

  &.right {
    flex-direction: row-reverse;
  }

  &--inline {
    color: $cta;

    &:hover {
      color: $cta-hover;
      border-color: $cta-hover;
    }

    &:active {
      border-color: $cta-active;
      color: $cta-active;
    }
  }
}