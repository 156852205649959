$hamburgerWidth: 36rem;

.header {
  overflow: hidden;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 10;


  &__inner {
    padding: 1.2rem 0;

    @include xxl-up {
      padding-left: 8rem !important;
      padding-right: 8rem !important;
    }
  }
  
  $height-icon: 2rem;
  $width-line: 3rem;
  $height-line: 0.46rem;

  $rotation: 45deg;
  $translateY: ($height-icon / 2);
  $translateX: 0;

  .hamburger {
    z-index: 100;
  }

  #hamburger-icon {
    width: $width-line;
    height: $height-icon;
    
    .line {
      width: $width-line;
      height: $height-line;
      left: 0;
      transition: all 0.3s;
      
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
      }
      &.line-3 {
        top: 100%;
      }
    }
    &:hover {
      .line-1 {
          transform: translateY($height-line / 2 * -1);
      }
      .line-3 {
          transform: translateY($height-line / 2);
      }  
    }
    &.active {
      .line-1 {
        transform: translateY($translateY) translateX($translateX) rotate($rotation);
        width: 3.85rem;;
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
        width: 3.85rem;
      }
    }
  }

  .hamburger-menu {
    bottom: 9rem;
    transform: translate(100%, 100%);
    z-index: 12;
    right: 0;
    width: 100%;
    transition: 0.3s transform ease-out;
    height: calc(100vh + 2rem);
    padding: 9rem 2.4rem 2.4rem;
    background-color: $white;
  
    @include md-up {
      padding: 9rem 8rem 8rem;
      width: 72rem;
    }

    @include xxl-up {
      width: 96rem;
    }

    a {
      color: $black;

      &:hover {
        color: $cta;
      }
    }

    &__item {
      @include font(3.6rem, 700, 120%);

      @include md-up {
        @include font(7.2rem, 700, 120%);
      }

      @include xxl-up {
        @include font(9.6rem, 700, 120%);
      }

      &--back {
        @include font(1.6rem, 400, 135%);

        svg {
          height: 1.6rem;
          width: 3rem;
        }
      }

      &.has-children {
        .hamburger-menu__submenu {
          top: 0;
          width: 100%;
          transform: translate(100%, 0);
          transition: 0.3s transform ease-out;
          height: 100%;
          background: $white;
          color: $black;
          z-index: 10;
          padding: 9rem 2.4rem 2.4rem;
          
          @include md-up {
            padding: 9rem 8rem 8rem;
            width: 72rem;
          }

          @include xxl-up {
            width: 96rem;
          }

          &.active {

            transform: translate(-3.2rem, 0);

            @include md-up {
              transform: translate(-8.8rem, 0);
            }
          }
        }
      }
    }
  }

  &.active {
    // overflow-y: visible;
    // overflow-x: clip;
    overflow: initial;

    .hamburger-menu {
      transform: translate(0, 100%);
      transition: 0.3s transform ease-in;
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.10);
      overflow: hidden;
    }
  }

  .menu__footer {
    justify-content: flex-end;

    @include md-up {
      justify-content: space-between;
    }

    .menu__footer_icon {
      display: flex;
      padding-left: 2.4rem;
      color: $primary-2;
      cursor: pointer;
      margin-bottom: 2.4rem;

      @include md-up {
        margin-bottom: 0;
        justify-content: center;
        padding-left: 0;
      }
  
      svg {
        fill: $primary-2;
        @include squaresize(2rem);
        margin-left: 0.8rem;
      }
  
      &:hover svg {
        fill: $primary;
        color: $primary;
      }
    }
  
  }

  .menu__footer_btns {

    // align-items: center;

    a {
      color: $primary-2;
      font-weight: 400;
  
      &.active {
        font-weight: 600;
        color: $primary;
      }
    }

    span {
      font-size: 1.6rem;
      line-height: 110%;

      @include md-up {
        font-size: 2.2rem;
        line-height: 100%;
      }

      @include xxl-up {
        font-size: 2.4rem;
        line-height: 120%;
      }
    }
  }

  &__logo {

    &.animate {
      animation-name: logoShow;
      animation-duration: 2s;
    }

    &:not(.mobile__view):hover {

      @include lg-up {
        #logoESSLetters {
          transition: all .3s linear;
          transform: translateX(0rem);
        }
  
        #logoUnderLetters {
          transform: translateY(0rem);
          opacity: 1;
          transition: all .3s linear;
        }
      }
    }

    &_a {
      position: relative;
      z-index: 10;
      background: $white;

      svg {
        height: 3rem;
      }
    }

    &_back {
      top: 0;
      left: 0;
      z-index: 1;

      #headerLogo {
        display: none;
      }

      #logoESSLetters {
        transform: translateX(-10rem);
        transition: all .3s linear;
      }

      #logoUnderLetters {
        transform: translateY(-1.6rem);
        opacity: 0;
        transition: all .3s linear;
      }
    }
  }


  #logoUpperLetters {
    height: 3rem;
  }
}

@keyframes logoShow {
  from {opacity: 0;}
  70% {opacity: 0;}
  to {opacity: 1;}
}
