body {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  font-style: normal;
  color: $body-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  font-style: normal;
  // text-rendering: auto;
  color: $black;
  margin-top: 0;
}

h1,
.h1 {
  font-size: $h1-font-size;
  line-height: 120%;

  @include md-up {
    font-size: 6rem;
    line-height: 110%;
  }

  @include xxl-up {
    font-size: 7.2rem;
  }
}

h2,
.h2 {
  font-size: $h2-font-size;
  line-height: 120%;

  @include md-up {
    font-size: 3.6rem;
    line-height: 130%;
  }

  @include xxl-up {
    font-size: 4.8rem;
  }
}

h3,
.h3 {
  font-size: $h3-font-size;
  line-height: 120%;
  font-weight: 400;

  @include md-up {
    font-size: 2.8rem;
  }

  @include xxl-up {
    font-size: 3.6rem;
  }
}

h4,
.h4 {
  font-size: $h4-font-size;
  line-height: 120%;
}

h5,
h6,
.h5,
.h6 {
  font-size: $h5-font-size;
  line-height: 120%;
}

p, li {
  text-rendering: auto;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  line-height: 135%;
  margin-top: 0;

  @include md-up {
    font-size: 2.0rem;
  }

  @include xxl-up {
    font-size: 2.6rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.page__title {
  margin: 2.4rem 0;
  @include font(4rem, 700, 120%);

  @include md-up {
    margin: 3.2rem 0;
    @include font(8.0rem, 700, 110%);
  }

  @include lg-up {
    margin: 4rem 0;
    @include font(12.0rem, 700, 110%);
  }

  @include xxl-up {
    margin: 6rem 0 4.8rem;
    @include font(16.0rem, 700, 110%);
  }
}

.page__subtitle {
  margin-bottom: 1.6rem;
  @include font(2.1rem, 400, 120%);
  font-family: $font-family-base;

  @include md-up {
    margin-bottom: 8rem;
    @include font(2.8rem, 400, 120%);
  }
}

.content-text {
  @include font(1.8rem, 400, 135%);

  @include md-up {
    font-size: 2.0rem;
  }
}


.text__lead,
.page__lead,
.text__lead p ,
.page__lead p {
  font-size: 2.2rem;
  line-height: 135%;
  font-weight: $font-weight-base;
  margin-bottom: 0;

  @include md-up {
    font-size: 2.4rem;
  }

  @include xxl-up {
    font-size: 3.2rem;
  }
}

.strong,
.strong p {
  font-weight: $font-weight-bold;
}

.small,
.spall p {
  font-size: 1.44rem;

  @include md-up {
    font-size: 1.6rem;
  }

  @include xxl-up {
    font-size: 2.0rem;
  }
}

button {
  font-family: $font-family-sans-serif;
}

a {
  text-decoration: none;
  color: $black;
}

ul,
ol {
  list-style-position: outside;
  margin-left: 0;
  padding-left: 1.8rem;
}

ol {
  padding-left: 2.3rem;
}

ul ul,
ol ol {
  margin-left: 0;
}

input,
textarea {
  @include font(2.0rem, 400, 135%);
  font-family: $font-family-base !important;
}

.semibold {
  font-weight: 600;
  font-size: 1.6rem;

  @include md-up {
    font-size: 2.0rem;
  }

  @include xxl-up {
    font-size: 2.6rem;
  }
}

blockquote {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 135%;

  &.huge,
  &.huge p {
    @include font(2.4rem, 700, 120%);
    font-style: italic;
    margin-bottom: 0;

    @include md-up {
      @include font(3.6rem, 700, 110%);
    }

    @include xl-up {
      @include font(5.6rem, 700, 110%);
    }

    @include xxl-up {
      @include font(7.2rem, 700, 115%);
    }
  }
}

figcaption {
  @include font(1.8rem, 400, 135%);

  @include md-up {
    @include font(2.0rem, 400, 135%);
  }

  @include xxl-up {
    @include font(2.6rem, 400, 135%);
  }
}

sub,
sup {
  line-height: 100%;
}

.section__subTitle {
  // color: $primary-2;
  font-size: 1.6rem;
  line-height: 135%;
}
