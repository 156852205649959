.filter {
  &__trigger {
    &.expanded {
      .filter__toggle {
        background-color: $primary;
        color: $white;

        .icon {
          transform: rotate(180deg);
        }
      }

      .filter__items {
        background-color: rgba($primary, 0.06);
      }
    }
  }

  &__items {
    .radio__wrapper {
      margin-right: 2.4rem;
    }

    &__all {

      &:after {
        content: '';
        width: 0.1rem;
        height: 100%;
        background-color: rgba($black, 0.06);
        position: absolute;
        right: 0;
        top: 0;
        display: block;
      }
    }
  }

  &__toggle {
    .icon {
      width: 0.74rem;
    }
  }

  &__newsletter {
    .icon {
      width: 2rem;
      color: $primary;
    }
  }
}