.slider {

  .swiper-wrapper {
    align-items: center;
  }

  &__inner {
    width: 100%;
  }

  .image {
    @include squaresize(100%);
    @include flex-center-center;
    max-height: 80vh;
    max-width: 80vw;
    overflow: hidden;

    
    img {
      object-fit: contain;
      // object-position: center;
      max-height: 80vh;
      max-width: 80vw;
      // min-width: 80%;
    }
  }

  .swiper-button-disabled {
    opacity: 0.35;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: auto;
    height: auto;
    padding: 2.4rem;
    z-index: 9 !important;

    .icon {
      height: 4.8rem;
      width: 3.6rem;
      fill: $primary;
    }

    &:after {
      display: none;
    }
  }

  .swiper-button-prev {
    .icon {
      transform: rotate(180deg);
    }
  }
}