.footer {
  &__address {
    p {
      margin: 0;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &__language {
    &-link {
      
      &:not(:last-child):after {
        content: " | ";
        padding: 0 10px;
      }
    }
  }

  &__sub {
    background: rgba($white, 0.14);
    color: $gray-600;
    font-size: $small-font-size;
    text-align: center;
  }
}