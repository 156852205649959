.contact-info {

  .phone-number {
    color: $white;
  }
  
  &__social {
    border-bottom: 0.1rem solid;
    .icon {
      max-width: 2rem;
      color: $white;
    }
 
    .separator-vertical {

      &:after {
        content: '';
        display: block;
        width: 0.1rem;
        height: 2.4rem;
        background-color: $white;
      }
    }
  }
}