.testimonials {
  background-color: rgba($primary, .20);

  &__text {
    max-width: 86.5rem;
    margin: 0 auto;
  }

  .swiper-pagination {
    position: relative;
    bottom: 0;
  }

  &__text {
    p:last-child {
      margin-bottom: 0;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 2.4rem;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);

    &:after {
      font-size: 2rem;
      padding: 2rem;
    }
  }
}