.search {
  &__inner {
    .icon--search {
      fill: $black;
      width: 2.4rem;
      height: 2rem;
    }
    &:hover .icon--search {
      fill: $cta;
    }
    &.active .icon--search {
      fill: $cta-active;
    }
  }
}