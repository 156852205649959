.projectList {

  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    order: 1;
    min-height: 100vh;

    @include md-up {
      grid-template-columns: repeat(3, 1fr);
      gap: 1.6rem;
      order: 0;
    }

    @include lg-up {
      gap: 3.2rem;
    }
  }

  &__column_2 {
    margin-top: 0rem;

    @include md-up {
      margin-top: 11.2rem;
    }
  }

  &__image {
    width: 100%;
    overflow: hidden;
    display: block;
    margin-bottom: 3.2rem;
    transition: all 0.8s ease;
    visibility: hidden;

    &__anim {
      transition: all 0.8s ease;
      transform: translateY(10rem);
      opacity: 0;
    } 

    .image.container {
      max-width: none;
    }

    &.active {
      transition: all 0.8s ease;
      visibility: visible;

      @include xl-up {
        margin-bottom: 6rem;
      }
    }

    // @include lg-up {
    //   &:hover img {
    //     filter: none;
    //     color: $black;
    //   }
    // }

    @media (pointer: fine) {
      &:hover img {
        filter: none;
        color: $black;
      }
    }

    &.landscape {
      img {
        aspect-ratio: 4 / 3;
      }
    }

    img,
    video{
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      filter: grayscale(1);
      aspect-ratio: 3 / 4;
      // aspect-ratio: 4 / 3;
    }

    video {
      aspect-ratio: 4 / 3;
    }
  }

  &__filter_mobile {
    visibility: visible;
    display: block;

    &.loading {
      visibility: hidden;
      display: none;
    }
  }

  &__item_description {
    margin-top: 2px;
  }

  &__filter_mobile {
    position: sticky;
    top: 7.8rem;
    z-index: 1;
  }

  &__filter {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    overflow: hidden;
    order: 0;

    @include md-up {
      order: 1;
      position: fixed;
      top: 8rem;
      right: 0;
    }

    &_item {

      // @include md-up {
      //   &:hover,
      //   &.active {
      //     color: $primary;
      //     cursor: pointer;
      //   }
      // }

      @media (pointer: fine) {
        &:hover {
          color: $primary;
          cursor: pointer;
        }
      }

      // @media (pointer: fine) {
      //   &:hover,
      //   &.active {
      //     color: $primary;
      //     cursor: pointer;
      //   }
      // }

      &.active {
        color: $primary;
        cursor: pointer;
      }

      &_desctop:not(.projectList__filter_item-title) {
        font-weight: 700;
        
        @include md-up {
          font-size: 1.8rem;
        }
        @include lg-up {
          font-size: 2.4rem;
        }
        @include xl-up {
          font-size: 2.6rem;
        }
      }
    }
  }

  .dropdown__main input::placeholder {
    color: $black;
  }
}