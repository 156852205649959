.galery-item {
  max-width: 31.2rem;
  width: 100%;

  @include lg-up {
    max-width: 39.5rem;
  }

  &__separator {
    width: calc(50% - 4.8rem);
    height: 0.2rem;
    background-color: $primary;
    display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  &__kpi {
    font-weight: 700;
    font-size: 4.8rem;
    line-height: 100%;
  }
}