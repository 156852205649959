.home {
  position: relative;

  @include lg-up {
    cursor: url('images/cursor.svg') 28 28, auto !important;

    // a {
    //   cursor: url('images/cursor.svg') 28 28, auto !important;
    // }

    button {
      cursor: pointer !important;
    }
  }

  &__mob {
    display: block;

    @include lg-up {
      display: none;
    }
  }

  &__desc {
    display: none;

    @include lg-up {
      display: block;
    }
  }

  video {
    width: 100%;
    object-fit: cover;

    @include lg-up {
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .swiper-wrapper {
    transform-style: preserve-3d;
    will-change: transform;
  }

  &__section {
    will-change: transform;
    transform-style: preserve-3d;

    @include lg-up {
      display: flex;
      height: calc(100vh - 9.4rem);
      width: 100vw;
    }
  }

  &__hero {
    overflow: hidden;
  }

  &__hero,
  &__teasers {

    .teaser__button::after {
      background-color: $white;
    }
  }

  &__kopmetenzen {
    padding: 4rem 2.4rem;

    @include lg-up {
      @include flex-center-center;
      padding: 0;
      cursor: url('images/cursor-black.svg') 28 28, auto !important;
    }

    .kompetenz__title {
      text-align: start;

      @include lg-up {
        text-align: end;
      }
    }
  }

  &__hero_item {
    display: inline-block;
    overflow: hidden;
    margin: 0;

    @include lg-up {
      cursor: url('images/cursor.svg') 28 28, auto !important;
    }

    &-mobile {
      img {
        aspect-ratio: 3 / 4;
      };
    }

    &:first-of-type {

      @include lg-up {
        margin-right: 2px;
      }
    }

    &:last-of-type {
      
      @include lg-up {
        margin-left: 2px;
      }
    }

    .image {
      mix-blend-mode: multiply;

      @include lg-up {
        mix-blend-mode: normal;
      }
    }

    &:hover .bg-red {
      display: block;
      mix-blend-mode: multiply;
    }

    &:hover .image {
      mix-blend-mode: multiply;
    }

    &:hover .home__hero_description {
      display: block;
    }
  }

  &__teasers_item {
    display: inline-block;
    overflow: hidden;
    margin: 0;

    @include lg-up {
      cursor: url('images/cursor.svg') 28 28, auto !important;
    }

    &:first-of-type {

      @include lg-up {
        margin-right: 1px;
      }
    }

    &:last-of-type {
      
      @include lg-up {
        margin-left: 1px;
      }
    }

    @include lg-up {
      height: calc(100vh - 9.4rem);
    }

    &:hover .bg-red {
      display: block;
      height: 100%;
      mix-blend-mode: multiply;
    }

    &:hover .image {
      mix-blend-mode: multiply;
    }

    &:hover .home__description {
      display: block;
    }
  }

  .home__teasers_item {
    height: 46.4rem;

    @include lg-up {
      height: calc((100vh - 9.4rem) / 2);
      width: calc(50% - 1px);
    }

    &-last {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
      margin-top: 4px;

      & * {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }

      @include lg-up {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
        margin-top: 0;
        margin-left: 2px;
        cursor: url('images/cursor__left.svg') 0 28, auto !important;

        & * {
          padding-left: 3.2rem;
          padding-right: 3.2rem;
        }
      }

      @include xl-up {
        padding-top: 8rem;
        padding-bottom: 8rem;

        & * {
          padding-left: 8rem;
          padding-right: 8rem;
        }
      }

      @include xxl-up {
        padding-top: 12rem;
        padding-bottom: 12rem;

        & * {
          padding-left: 12rem;
          padding-right: 12rem;
        }
      }
    }

    &_2 {
      margin: 0;
      margin-top: 4px;

      @include lg-up {
        margin: 0;
        margin-left: 1px;
      }
    }

    &_3 {
      margin: 0 !important;
      margin-top: 4px !important;
      width: 100%;

      @include lg-up {
        margin-top: 2px !important;
      }
    }
  }
  

  a {
    box-sizing: border-box;
  }

  &__hero_description {
    display: block;
    bottom: 4rem;
    left: 2.4rem;

    @include lg-up {
      display: none;
    }

    @include lg-up {
      bottom: 8rem;
      left: 50.6%;
    }

    @include xxl-up {
      bottom: 12rem;
      left: 54%;
    }

    &.mobile__view {
      display: block;
    }
  }

  &__description {
    display: block;
    left: 2.4rem;
    bottom: 2.4rem;

    @include lg-up {
      display: none;
    }

    @include lg-up {
      left: 4rem;
      bottom: 4rem;
    }
    
    &.mobile__view {
      display: block;
    }
  }

  .image {
    height: 100%;

    &.container {
      max-width: none;
    }
  }

  img {
    object-fit: cover;
    object-position: center;
    filter: grayscale(1);
    height: 100%;
  }

  &__animation {
    display: none;
  }

  &__animation_active {
    display: block;
    position: absolute;
    height: calc(100vh - 7.4rem);
    width: 100vw;
    @include flex-center-center;
    z-index: 10;
    overflow: hidden;     
    width: 0px;

    animation-name: collapse;
    animation-duration: 2s;

    @include lg-up {
      height: calc(100vh - 9.4rem);
    }

    &_text {
      animation-name: showMobile;
      animation-duration: 2s;

      @include lg-up {
        animation-name: show;
      }

      svg {
        width: 32rem;

        @include lg-up {
          width: auto;
        }
      }
    }

    #xLetterDown {
      transform-origin: 33% 33%;
      animation-name: xLetterDownRotate;
      animation-duration: 2s;
    }

    #xLetterUp {
      transform-origin: 33% 33%;
      animation-name: xLetterUpRotate;
      animation-duration: 2s;
    }

    #aLetter {
      animation-name: aMove;
      animation-duration: 2s;
    }

    #essLetters {
      animation-name: essMove;
      animation-duration: 2s;
    }

    #secondLine {
      animation-name: hide;
      animation-duration: 2s;
    }

    img {
      filter: none;
    }
  }
}

.bg-red {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(0,0,0,0) 51%, rgba(0,0,0,1) 100%);
  mix-blend-mode: multiply;

  @include lg-up {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: none;
    background-color: $primary;
    // mix-blend-mode: multiply;
    display: none;
    mix-blend-mode: normal;
  }

  &.mobile__view {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(0,0,0,0) 51%, rgba(0,0,0,1) 100%);
    mix-blend-mode: multiply;
  }
}



@keyframes show {
  from {opacity: 0;}
  10% {opacity: 0;}
  25% { opacity: 1; transform: none;}
  40% {transform: none;}
  80% {transform: translateX(12rem);}
  to {opacity: 1; transform: translateX(12rem);}
}

@keyframes showMobile {
  from {opacity: 0;}
  10% {opacity: 0;}
  25% { opacity: 1; transform: none;}
  40% {transform: none;}
  80% {transform: translateX(6rem);}
  to {opacity: 1; transform: translateX(6rem);}
}

@keyframes xLetterDownRotate {
  from {transform: none;}
  30% {transform: none;}
  60% {
    transform-origin: 33% 33%;
    transform: rotate(45deg);
  }
  to {
    transform-origin: 33% 33%;
    transform: rotate(45deg);
  }
}

@keyframes xLetterUpRotate {
  from {transform: none;}
  30% {transform: none;}
  60% {
    transform-origin: 33% 33%;
    transform: rotate(-45deg);
  }
  to {
    transform-origin: 33% 33%;
    transform: rotate(-45deg);
  }
}

@keyframes collapse {
  from {
    width: 100vw;
    inset: 0;
  }
  60% {
    width: 100vw;
    inset: 0;
  }
  80% {
    width: 0px;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
  }
  to {
    width: 0px;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
  }
}

@keyframes aMove {
  from {
    transform: none;
  }

  40% {
    transform: none;
    opacity: 1;
  }

  80% {
    transform: translateX(6rem);
    opacity: 0;
  }

  to {
    transform: translateX(6rem);
    opacity: 0;
  }
}

@keyframes essMove {
  from {
    transform: none;
  }

  40% {
    transform: none;
    opacity: 1;
  }

  80% {
    transform: translateX(-5rem);
    opacity: 0;
  }

  to {
    transform: translateX(-5rem);
    opacity: 0;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}
