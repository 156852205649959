.navigation {
  &__item {
    color: $black;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      color: $cta;
    }

    &.active {
      color: $cta-active;
      border-bottom: 2px solid;
    }
  }
}