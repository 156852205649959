.lead {
  padding: 4.8rem 0;
  max-width: 131.2rem;

  &.simple {
    background-color: $white;
    padding-bottom: 0;
  }

  & ~ .kpi {
    margin-top: 2.4rem;
  }

  @include xl-up {
    padding: 6.4rem 0;
  }

  &__inner {
    // padding: 0 16.66%
    max-width: 100%;
    margin: 0;
  }

  &__content {
    p {
      font-size: 2rem;
    }
  }

  &__cta {
    .btn {
      margin: 0 auto;
    }
  }
}