.popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transition: opacity 0.4s ease;
  opacity: 0;
  z-index: -99999;

  &-show {
    opacity: 1;
    z-index: 99999;
  }
  
  &-close {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(7, 25, 37, 0.75);

    &-btn {
      position: absolute;
      top: -6rem;
      right: 0rem;
      padding: 3.7rem;
      cursor: pointer;
      z-index: 1000;

      svg {
        fill: $white;
      }

      &:hover svg {
        fill: $primary;
      }
    }
  }
  
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    // background-color: $primary;
    max-width: 80vw;
    max-height: 80vh;
    // padding: 1.6rem;
    transform: translate(-50%, -50%);
  }
}
