// Normal font-family
@font-face {
  font-family: 'Oscine Trial';
  src:
    url('fonts/Oscine_Trial_Rg.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Bold font-family
// @font-face {
//   font-family: 'Red-Hat-Bold';
//   src:
//     url('fonts/RedHatDisplay-Bold.ttf') format('ttf');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }
