@import '_config/mixins';
@import '~bootstrap/scss/functions';

@import '_config/fonts';
@import '_config/variables';

@import '_config/utilities';
@import 'bootstrap/scss/bootstrap-grid.scss';
@import 'bootstrap/dist/css/bootstrap-utilities.min.css';
@import '_config/settings';

//NPM packages
@import 'swiper/swiper-bundle';


@import '01-atoms/typography/typography';
@import '01-atoms/button/button';
@import '01-atoms/icons/icon';
@import '01-atoms/navigation/navigation';
@import '01-atoms/readmore/readmore';
@import '01-atoms/inputs/inputs';
@import '01-atoms/galeryItem/galeryItem';
@import '01-atoms/personProfile/personProfile';
@import '01-atoms/sectionTitle/section-title';

@import '02-molecules/accordion/accordion';
@import '02-molecules/alerts/alerts';
@import '02-molecules/hero/hero';
@import '02-molecules/popup/popup';
@import '02-molecules/iconList/iconList';
@import '02-molecules/share/share';
@import '02-molecules/slider/slider';
@import '02-molecules/lead/lead';
@import '02-molecules/search/search';
@import '02-molecules/contactBlock/contactBlock';
@import '02-molecules/testimonials/testimonials';
@import '02-molecules/newsTeaser/newsTeaser';
@import '02-molecules/newsGrid/newsGrid';
@import '02-molecules/threeTeaser/threeTeaser';
@import '02-molecules/pageTitle/pageTitle';
@import '02-molecules/pageSlider/pageSlider';
@import '02-molecules/contactInfo/contactInfo';
@import '02-molecules/pagination/pagination';
@import '02-molecules/filter/filter';
@import '02-molecules/newsGrid/newsGrid';
@import '02-molecules/content/content';
@import '02-molecules/dialogues/dialogues';
@import '02-molecules/aside/aside';
@import '02-molecules/pageNavigation/pageNavigation';
@import '02-molecules/teaser/teaser';
@import '02-molecules/team/team';
@import '02-molecules/contactForm/contactForm';
@import '02-molecules/project/project';
@import '02-molecules/projectList/projectList';
@import '02-molecules/gallery/gallery';
@import '02-molecules/kurzportrait/kurzportrait';

@import '03-organisms/header/header';
@import '03-organisms/footer/footer';
