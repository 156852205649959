.accordion {
  &__item {
    border-bottom: 0.1rem solid $gray-500;
    max-height: 4rem;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  &__title {
    & svg {
      transition: all 0.5s ease;
      transform: rotate(-90deg);
      width: 2.4rem;
      height: 2.4rem;
      color: $cta;
    }
  }

  &__open {
    max-height: 100vh;

    & .accordion__title {
      & svg {
        transform: rotate(0deg);
      }
    }
  }
}